import { PageProps } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import BasicScreen from "../components/BasicScreen";
import Box from "../components/Box";
import "../style/index.scss";

const IndexPage = (props: PageProps) => {
  return (
    <BasicScreen {...props} effect>
        <a href="https://gutenbergovpark.com" target="_blank" rel="noopener noreferrer">
      <Box>
          <StaticImage src="../images/gutenbergov.png" alt="Gutenbergov Park" className="h-20 w-20 object-contain" />
          <span className="mt-6 text-center text-white text-2xl">VIŠEPORODIČNI STAMBENO-POSLOVNI OBJEKAT P+3</span>
      </Box>
        </a>
    </BasicScreen>
  );
};

export default IndexPage;
